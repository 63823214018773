import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
//import DocsExample from '@/components/DocsExample'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

/*const token = localStorage('token')

if (token) {
  store.commit({
    type: 'updateToken',
    value: token,
  })
}*/

app.config.globalProperties.$axios = axios
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.state.token}`
  config.baseURL = `https://api.w2edu.kr` //`http://localhost:52400`  //`https://api.w2edu.kr`
  return config
})

app.use(store)
app.use(router)
app.use(CoreuiVue)

app.provide('icons', icons)
app.component('CIcon', CIcon)
//app.component('DocsExample', DocsExample)

app.component('VueDatePicker', VueDatePicker);

app.mount('#app')
